import { Controller } from 'stimulus';

import React from 'react';
import ReactDOM from 'react-dom';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */

const Test = props => {
  return "bb"
}

export default class extends Controller {
    connect() {
      ReactDOM.render(<Test/>, this.element);
    }
}