/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import $ from 'jquery';
import daterangepicker from '../public/admin/js/datepicker/daterange-picker/daterangepicker.js';

import '../public/admin/css/vendors/daterange-picker.css';

const all_translations = {
    fr: {
        calendar: {
            format: 'DD/MM/YYYY',
            "separator": " - ",
            "applyLabel": "Appliquer",
            "cancelLabel": "Annuler",
            "fromLabel": "Arrivée",
            "toLabel": "Départ",
            "customRangeLabel": "Personnalisé",
            "weekLabel": "S",
            "daysOfWeek": [
                "Di",
                "Lu",
                "Ma",
                "Me",
                "Je",
                "Ve",
                "Sa"
            ],
            "monthNames": [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Decembre"
            ],
            "firstDay": 1
        },
        'Réservation non confirmée': 'Réservation non confirmée',
        'Réservé par le propriétaire': 'Réservé par le propriétaire',
        'Réservé par l\'agence': 'Réservé par l\'agence',
        'Indisponible': 'Indisponible',
        'Pas disponible': 'Pas disponible',
        'Date d\'arrivée': 'Date d\'arrivée',
        'Date de départ': 'Date de départ',
    },
    en: {
        calendar: {
            format: 'DD/MM/YYYY',
        },
        'Réservation non confirmée': 'Unconfirmed reservation',
        'Réservé par le propriétaire': 'Booked by owner',
        'Réservé par l\'agence': 'Booked by agency',
        'Indisponible': 'Unavailable',
        'Pas disponible': 'Not available',
        'Date d\'arrivée': 'Arrival Date',
        'Date de départ': 'Departure Date',
    },
};

window.calendar = function (opts) {
    let translations = all_translations[document.documentElement.lang];

    let {url, id, bien_element_id, bien_id, exclude_id} = opts;

    let id_from = id + '_from';
    let id_to = id + '_to';
    let bien_data = [];

    let today = new Date();
    today = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');

    function updateData() {
        if(!bien_element_id && !bien_id)
            return;

        document.getElementById(id).disabled = true;
        if(document.getElementById(id_from))
            document.getElementById(id_from).disabled = true;
        if(document.getElementById(id_to))
            document.getElementById(id_to).disabled = true;
        let url_bien_id = bien_element_id ? $('#'+bien_element_id).val():bien_id;
        if(url_bien_id) {
            $.get(url.replace('_BIEN_ELEMENT_ID_', url_bien_id)+(exclude_id ? '?exclude_id='+exclude_id:''), function(data) {
                bien_data = data;
                document.getElementById(id).disabled = false;
                if(document.getElementById(id_from))
                    document.getElementById(id_from).disabled = false;
                if(document.getElementById(id_to))
                    document.getElementById(id_to).disabled = false;

                if(opts.onCompleted)
                    opts.onCompleted();
            })
        }
        else {
            bien_data = [];
            document.getElementById(id).disabled = false;
            if(document.getElementById(id_from))
                document.getElementById(id_from).disabled = false;
            if(document.getElementById(id_to))
                document.getElementById(id_to).disabled = false;

            if(opts.onCompleted)
                opts.onCompleted();
        }
    }
    if(bien_element_id) {
        $(document).on('change', '#'+bien_element_id, function() {
            updateData();
        });
    }
    updateData();

    let clickDepart = false;

    $('input[id="'+id_from+'"]').click(function() {
        clickDepart = false;
        $('input[id="'+id+'"]').click();
    });
    $('input[id="'+id_to+'"]').click(function() {
        clickDepart = true;
        $('input[id="'+id+'"]').click();
    });
    $('input[id="'+id+'"]').daterangepicker({
        autoApply: true,
        isCustomDate: function(a) {
            let date = a.format('YYYY-MM-DD');

            if(date < today)
                return 'old';

            for(let r of bien_data) {
                // date after the start
                if(date > r[0]) {
                    // and before the end
                    if(date < r[1])
                        return r[2];
                }
                else
                    break;
            }

            return '';
        },
        isInvalidDate: function(a) {
            let picker = $('#'+id).data('daterangepicker');

            // date before today, so disabled
            if(!document.location.href.includes('/admin/')) {
                let yesterday = new Date();
                yesterday. setDate(yesterday.getDate() - 1);
                if(a.isBefore(yesterday))
                    return true;
            }

            let date = a.format('YYYY-MM-DD');

            for(let r of bien_data) {
                // picking the end date
                if(picker.startDate && !picker.endDate && date > picker.startDate.format('YYYY-MM-DD')) {
                    // date after the start
                    if(date > r[0]) {
                        // range was before start date
                        if(picker.startDate.format('YYYY-MM-DD') >= r[1])
                            continue;
                        return true;
                    }
                    else
                        break;
                }
                else {
                    // date after the start
                    if(date >= r[0]) {
                        // and before the end
                        if(date < r[1])
                            return true;
                    }
                    else
                        break;
                }
            }

            return false;
        },
        locale: translations.calendar
    });
    $('input[id="'+id+'"]').on('change', function(ev) {
        let picker = $('#'+id).data('daterangepicker');
        $('input[id="'+id_from+'"]').val(picker.startDate.format(picker.locale.format));
        $('input[id="'+id_to+'"]').val(picker.endDate.format(picker.locale.format));
    });
    $('input[id="'+id+'"]').on('apply.daterangepicker', function(ev, picker) {
        $('input[id="'+id_from+'"]').val(picker.startDate.format(picker.locale.format));
        $('input[id="'+id_to+'"]').val(picker.endDate.format(picker.locale.format));
    });

    $('input[id="'+id+'"]').on('showCalendar.daterangepicker', function(ev, picker) {
        if(!document.location.href.includes('/admin/')) {
            if(window.screen.width < 400) {
                let el = $('.daterangepicker .left thead tr:first-child th:nth-child(3)');
                if(el.length)
                    el.replaceWith($('<th class="next available"><i class="fa fa-chevron-right glyphicon glyphicon-chevron-right"></i></th>'));
                else
                    $('.daterangepicker .left thead').append($('<th class="next available"><i class="fa fa-chevron-right glyphicon glyphicon-chevron-right"></i></th>'));
            }
        }
    });

    $('input[id="'+id+'"]').on('update.daterangepicker', function(ev, picker) {
        if(clickDepart) {
            clickDepart = false;
            picker.endDate = null;
            picker.updateView();
            return;
        }
        $('.daterangepicker .legend').remove();

        if(document.location.href.includes('/admin/')) {
            $('.daterangepicker').append(`<div class="legend">
                <span class="reservation_non_signe"></span> ${translations['Réservation non confirmée']}<br/>
                <span class="reservation_reserve_proprietaire"></span> ${translations['Réservé par le propriétaire']}<br/>
                <span class="reservation_reserve_agence"></span> ${translations['Réservé par l\'agence']}<br/>
                <span class="reservation_indisponible"></span> ${translations['Indisponible']}
            </div>`);
        }
        else {
            $('.daterangepicker .left .daterangepicker_input').css('display', 'none');
            $('.daterangepicker .right .daterangepicker_input').css('display', 'none');
            $('.daterangepicker .title').remove();

            if(window.screen.width < 400) {
                $('.daterangepicker .right').css('display', 'none');

                let el = $('.daterangepicker .left thead tr:first-child th:nth-child(3)');
                if(el.length)
                    el.replaceWith($('<th class="next available"><i class="fa fa-chevron-right glyphicon glyphicon-chevron-right"></i></th>'));
                else
                    $('.daterangepicker .left thead').append($('<th class="next available"><i class="fa fa-chevron-right glyphicon glyphicon-chevron-right"></i></th>'));
            }


            let arrivee = $('.daterangepicker .left .daterangepicker_input .active').length > 0;
            $("<div class=\"title\">"+(arrivee ? translations['Date d\'arrivée']:translations['Date de départ'])+"</div>").insertBefore($('.daterangepicker .left .calendar-table'));
            $("<div class=\"title\">&nbsp;</div>").insertBefore($('.daterangepicker .right .calendar-table'));

            $('.daterangepicker').append('<div class="legend"><span class="pas_disponible"></span> '+translations['Pas disponible']+'</div>');
        }
    });

    let range = $('input[id="'+id+'"]').val().split(" - ");
    $('input[id="'+id_from+'"]').val(range[0]);
    $('input[id="'+id_to+'"]').val(range[1]);
}